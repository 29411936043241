import axios from 'axios';

const getCars = async (query) => {

    let data = null;
    let baseUrl = process.env.REACT_APP_API_ENDPOINT;

    await axios.get(`${baseUrl}/glido/cars?${query}`)
        .then((res) => {
            data = JSON.parse(JSON.stringify(res.data))
        })
        .catch(error => {
            data = JSON.parse(JSON.stringify(error.response.data))
        });
    return data;
}

const getCarsByLocation = async () => {

    let data = null;
    let baseUrl = process.env.REACT_APP_API_ENDPOINT;

    await axios.get(`${baseUrl}/glido/cars/location`)
        .then((res) => {
            data = JSON.parse(JSON.stringify(res.data))
        })
        .catch(error => {
            return error;
        });
    return data;
}

const getCarsByBrands = async () => {

    let data = null;
    let baseUrl = process.env.REACT_APP_API_ENDPOINT;

    await axios.get(`${baseUrl}/glido/cars/brands`)
        .then((res) => {
            data = JSON.parse(JSON.stringify(res.data))
        })
        .catch(error => {
            return error;
        });
    return data;
}

const getVendor = async () => {

    let data = null;
    let baseUrl = process.env.REACT_APP_API_ENDPOINT;

    await axios.get(`${baseUrl}/glido/vendors`)
        .then((res) => {
            data = JSON.parse(JSON.stringify(res.data))
        })
        .catch(error => {
            return error;
        });
    return data;
}

const addCar = async (params) => {

    let data = null;
    let baseUrl = process.env.REACT_APP_API_ENDPOINT;

    await axios.post(`${baseUrl}/glido/cars`,params)
        .then((res) => {
            data = JSON.parse(JSON.stringify(res.data))
        })
        .catch(error => {
            return error;
        });
    return data;
}

const registerVendor = async (params) => {

    let data = null;
    let baseUrl = process.env.REACT_APP_API_ENDPOINT;

    await axios.post(`${baseUrl}/glido/vendors`,params)
        .then((res) => {
            data = JSON.parse(JSON.stringify(res.data))
        })
        .catch(error => {
            return error;
        });
    return data;
}

const registerBooking = async (params) => {
    let data = null;
    let baseUrl = process.env.REACT_APP_API_ENDPOINT;

    await axios.post(`${baseUrl}/glido/bookings`, params)
        .then((res) => {
            data = JSON.parse(JSON.stringify(res.data))
        })
        .catch(error => {
            return error;
        });
    return data;
}

export {
    getCarsByBrands,
    registerVendor,
    registerBooking,
    getCars,
    getCarsByLocation,
    getVendor,
    addCar
};