import React, { useEffect } from 'react';
import '../asset/style/about.scss';
import Header from './header';
import customerImage from '../asset/images/gcustomers.jpg'; // Add your image path
import ownerImage from '../asset/images/hosts.jpeg'; // Add your image path
import hostImage from '../asset/images/connection.jpeg'; // Add your image path


function About() {
    useEffect(() => {
        // Add animation class to each about-container one by one
        const containers = document.querySelectorAll('.about-container');
        containers.forEach((container, index) => {
            setTimeout(() => {
                container.classList.add('animate');
            }, index * 300); // Delay each animation by 300ms
        });
    }, []);

    return (
        <>
            <Header />
            <div className="about-page">
                <div className="about-container customer">
                    <div className="content-wrapper">
                        <img src={customerImage} alt="Customer" className='about-image' />
                        <div className="text-content">
                            <h1>Glido for Customer</h1>
                            <p>
                                Glido provides you the cheapest self-drive rental cars on an hourly, daily, or monthly subscription basis. We ensure the best-in-class experience for customers. Our self-drives undergo 50+ checks before onboarding.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="about-container owner">
                    <div className="content-wrapper reverse">
                        <img src={ownerImage} alt="Owner" className='about-image' />
                        <div className="text-content">
                            <h1>Glido for Owners</h1>
                            <p>
                                We empower local businesses by connecting them to a wider audience and reducing their marketing costs. Our platform helps car vendors grow by increasing their visibility and bringing more customers to their doorstep.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="about-container host">
                    <div className="content-wrapper">
                        <img src={hostImage} alt="Host" className='about-image' />
                        <div className="text-content">
                            <h1>Glido for Host</h1>
                            <p>
                                Our host services manage the entire booking journey for car owners, including pickup, drop-off, cleaning, and addressing any booking issues.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default About;
