import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import Header from './header'
import Linkedin from '../asset/images/linkedin.png'
import Facebook from '../asset/images/facebook.png'
import Instagram from '../asset/images/instagram.png'

import UserProfile from '../asset/images/user-profile.png'
import Rupee from '../asset/images/rupee.png'
import Quality from '../asset/images/quality.png'
import Hour from '../asset/images/hour.png'
import topImage from '../asset/images/driver.svg'
import Modal from './modal';
import Money from '../asset/images/money.svg'
import Cleaner from '../asset/images/cleaner.svg'; // Add your image path

import Support from '../asset/images/support.svg'
import keys from '../asset/images/fast-car.svg'
import { registerVendor } from '../services/glido.service';
import { getCarsByLocation } from '../services/glido.service';
import { useNavigate } from "react-router-dom";

import '../asset/style/home.scss'

function Home() {

    const [formData, setFormData] = useState({
        vendorName: '',
        phoneNumber: '',
        email: '',
        state: ''
    });

    const [location, setLocation] = useState("Banglore");
    const [locationOptions, setLocationOptions] = useState([]);
    const [isLocationModal, setLocationModal] = useState(false);

    const [isModal, setIsModal] = useState(false)
    const [withDriver, setWithDriver] = useState(false)
    const [purpose, setPurpose] = useState(0)
    const navigate = useNavigate();

    useEffect(() => {
        handleGetCarsByLocation();
    }, [])

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleGetCarsByLocation = async () => {
        let response = await getCarsByLocation();
        setLocationOptions(response.map((location) => {
            return capitalizeFirstLetter(location)
        }))
    }
    function capitalizeFirstLetter(string) {
        return [...string][0].toUpperCase() + [...string].slice(1).join('')
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
        let data = {
            name: formData.vendorName,
            contact: formData.phoneNumber,
            location: formData.state,
            email: formData.email
        }
        let response = registerVendor(data)
        setIsModal(false)
        console.log(response);
    };

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };

    const handleBookingPage = () => {
        navigate(`/book?location=${location.toLowerCase()}`)
    }

    return (
        <>
            <Header />


            <div className='booking-form-container'>
                <Modal open={isModal} >

                    <div class="modal-header">
                        <div class="close-button" onClick={(e) => { setIsModal(false) }}>X</div>
                        <h2 class="modal-title">Host</h2>
                    </div>

                    <form className="booking-form">
                        <div className="form-group">
                            <label htmlFor="name">Vendor Name:</label>
                            <input
                                type="text"
                                id="vendorName"
                                name="vendorName"
                                value={formData.vendorName}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="phone">Phone Number:</label>
                            <input
                                type="tel"
                                id="phoneNumber"
                                name="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="phone">Email:</label>
                            <input
                                type="tel"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="phone">State:</label>
                            <input
                                type="tel"
                                id="state"
                                name="state"
                                value={formData.state}
                                onChange={handleChange}
                                required
                            />
                        </div>


                    </form>

                    <div class="footer">
                        <button class="clear-button">Close</button>
                        <button class="show-button simple-button" onClick={handleSubmit}>Submit</button>
                    </div>
                </Modal>
            </div>

            <div className='locations-container'>
                <Modal open={isLocationModal} >

                    <div class="modal-header">
                        <h2 class="modal-title">Locations</h2>
                        <div class="close-button" onClick={(e) => { setLocationModal(false) }}>X</div>
                    </div>

                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer" }}>
                        {
                            locationOptions.map((location) => <>
                                <p onClick={(e) => { setLocation(location); setLocationModal(false) }}>{location}</p>
                            </>)
                        }
                    </div>
                </Modal>

            </div>



            <div className='home-container'>
                <div className='left-container'>
                    <div class="container">
                        <div class="tabs">
                             <div class={`tab ${purpose == 1 ? 'active':''}`} onClick={()=>{setPurpose(1)}}>Subscriptions<br />For more than 1 month</div>
                            <div class={`tab ${purpose == 0 ? 'active':''}`} onClick={()=>{setPurpose(0)}}>Rentals<br />For hours & days</div>
                            
                        </div>
                        <div class="rental-type">Glido Rentals</div>
                        <div class="subtitle">Affordable Rides, Profitable Drives </div>
                        <div class="toggle">
                            <div class={`toggle-option ${!withDriver ? 'active':''}`} onClick={()=>{setWithDriver(false)}}>Self Drive</div>
                            <div class={`toggle-option ${withDriver ? 'active':''}`} onClick={()=>{setWithDriver(true)}}>With Driver</div>
                        </div>
                        <div class="input-group">
                            <span>📍</span>
                            <input type="text" placeholder={`${location}`} />
                            <span style={{cursor: "pointer"}} onClick={handleBookingPage}>➔</span>
                        </div>
                        <div class="city-change" onClick={(e) => { setLocationModal(true) }}>Want to book in different city?</div>
                    </div>
                </div>
                <div className='right-container'>
                    <img src={topImage} alt="Glidgo Cars" />
                </div>
            </div>


            <div className="why-choose-glidgo">
                <h2>Why Choose Glido Cars?</h2>
                <div className="features-container">
                    <div className="feature-box">
                        <div className="feature-icon">
                            <img src={Rupee} alt="Price" />
                        </div>
                        <h3>Lowest Prices</h3>
                        <p>We offer the lowest prices guaranteed in the market.</p>
                    </div>
                    <div className="feature-box">
                        <div className="feature-icon">
                            <img src={Quality} alt="Quality" />
                        </div>
                        <h3>Top Quality</h3>
                        <p>Every car undergoes 50+ checks to ensure you get a vehicle in perfect condition.</p>
                    </div>
                    <div className="feature-box">
                        <div className="feature-icon">
                            <img src={Hour} alt="Convenience" />
                        </div>
                        <h3>Convenience</h3>
                        <p>Choose from 80+ car types, and book from anywhere in India.</p>
                    </div>
                </div>
            </div>


            {/* <div class="slanted-div">
                <h2>We Treat Your Car Like Our Own</h2>

                <div class="offering-container">
                    <div class="offering-item">
                        <img src={keys} alt="Car Pickup" />
                        <h3>Car Pickup</h3>
                        <p>Our host will come to pick your car from your location.</p>
                    </div>

                    <div class="offering-item">
                        <img src={Money} alt="Car Cleaned" />
                        <h3>Car Cleaned</h3>
                        <p>Host will get your car cleaned and returned to your location.</p>
                    </div>

                    <div class="offering-item">
                        <img src={climbing} alt="Customer Handling" />
                        <h3>Customer Handling</h3>
                        <p>Host will handle all customer-related queries with ease.</p>
                    </div>
                </div>

                <div class="cta-container">
                    <p>Elevate your local business with Glidgo's innovative solutions and take your productivity to new heights.</p>
                    <button onClick={(e) => { setIsModal(true) }}>Connect with Us</button>
                </div>
            </div> */}

            <div className="slanted-div">
                <h2>We Treat Your Car Like Our Own</h2>

                {/* Slider for mobile view */}
                <div className="offering-slider">
                    <Slider {...sliderSettings}>
                        <div className="offering-item">
                            <img src={keys} alt="Car Pickup" />
                            <h3>Car Pickup</h3>
                            <p>Our host will come to pick your car from your location.</p>
                        </div>

                        <div className="offering-item">
                            <img src={Cleaner} alt="Car Cleaned" />
                            <h3>Car Cleaned</h3>
                            <p>Host will get your car cleaned and returned to your location.</p>
                        </div>

                        <div className="offering-item">
                            <img src={Support} alt="Customer Handling" />
                            <h3>Customer Handling</h3>
                            <p>Host will handle all customer-related queries with ease.</p>
                        </div>
                    </Slider>
                </div>

                {/* Grid view for desktop */}
                <div className="offering-container">
                    <div className="offering-item">
                        <img src={keys} alt="Car Pickup" />
                        <h3>Car Pickup</h3>
                        <p>Our host will come to pick your car from your location.</p>
                    </div>

                    <div className="offering-item">
                        <img src={Cleaner} alt="Car Cleaned" />
                        <h3>Car Cleaned</h3>
                        <p>Host will get your car cleaned and returned to your location.</p>
                    </div>

                    <div className="offering-item">
                        <img src={Support} alt="Customer Handling" />
                        <h3>Customer Handling</h3>
                        <p>Host will handle all customer-related queries with ease.</p>
                    </div>
                </div>

                <div className="cta-container">
                    <p>Elevate your local business with Glidgo's innovative solutions and take your productivity to new heights.</p>
                    <button onClick={(e) => { setIsModal(true) }}>Connect with Us</button>
                </div>
            </div>


            <div className="whitespace-div">

            </div>

            <div className="reviews-section">
                <h2>What Our Customers Say</h2>
                <div className="reviews-container">
                    <div className="review-box"><img src={UserProfile} /> Fantastic service and very user-friendly booking process! <p>- Raj S.</p> </div>
                    <div className="review-box"><img src={UserProfile} /> Efficient and reliable. Glido Cars made our trip unforgettable!<p>- Maya L.</p> </div>
                    <div className="review-box"><img src={UserProfile} /> Top-notch quality and excellent customer support. Will use Glido again! <p>- Anil P.</p> </div>
                    <div className="review-box"><img src={UserProfile} /> Great value for money and easy to navigate website. Highly satisfied!<p>- Neha G.</p> </div>
                    <div className="review-box"><img src={UserProfile} /> Impressed by the quick response time and seamless rental experience!<p>- Arjun M.</p> </div>
                    <div className="review-box"><img src={UserProfile} /> Glido’s cars are well-maintained and their service is exceptional. Worth every penny! <p>- Sara T.</p></div>
                    <div className="review-box"><img src={UserProfile} /> Effortless booking and amazing customer care. Glido exceeded our expectations!  <p>- Vikram H.</p> </div>
                    <div className="review-box"><img src={UserProfile} /> A truly enjoyable rental experience with Glido. Highly recommended for their reliability! <p>- Aisha N.</p> </div>
                    <div className="review-box"><img src={UserProfile} /> Great selection of cars and transparent pricing. Will definitely choose Glido again! <p>- Riya S.</p> </div>
                    <div className="review-box"><img src={UserProfile} /> Smooth process from start to finish. Glido made our travel plans stress-free! <p> - Harsh V.</p> </div>

                </div>
            </div>

            <div className="home-footer">
                <p className='tagline'>Cruise In, Drive Out</p>
                <div className='footer-content'>
                    <div className='footer-contact'>
                        <p className="footer-title">Contact</p>
                        <p>
                            3696, Sector 23, Gurgaon, 122017
                        </p>
                        <p>
                            General Inquiry: <br /> +91-9413333049
                        </p>
                    </div>
                    <div className='footer-touch'>
                        <p className="footer-title">Get in Touch</p>
                        <p>
                            We are available 24/7 to assist with your queries. Follow us on social media or contact us directly.
                        </p>
                        <div className='social-icons'>
                            <a href="https://www.linkedin.com/company/glido-wheels" target="_blank" rel="noopener noreferrer">
                                <img className='social-img' src={Linkedin} alt="LinkedIn" />
                            </a>
                            <a href="https://www.instagram.com/glido.wheels" target="_blank" rel="noopener noreferrer">
                                <img className='social-img' src={Instagram} alt="Instagram" />
                            </a>
                            <a href="https://www.facebook.com/glido.wheels" target="_blank" rel="noopener noreferrer">
                                <img className='social-img' src={Facebook} alt="Facebook" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="home-footer-copyright"></div>
        </>
    )
}

export default Home
