import logo from './logo.svg';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import './App.css';
import Home from './component/home'
import Cars from './component/cars';
import About from './component/about';
import CarForm from './component/admin';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/book" element={<Cars />} />
        <Route path="/admin" element={<CarForm />} />
      </Routes>
    </Router>
  );
}

export default App;
