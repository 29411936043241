import React from 'react'
import '../asset/style/modal.scss';

function Modal({ open,children, setIsOpen}) {
  if(!open) return null

  return (
    <div className='overlay'>
    <div className='modal'>
      {children}
    </div>
    </div>
    
  )
}

export default Modal