import React, { useEffect, useState } from 'react';
import '../asset/style/admin.scss';
import { useNavigate, useLocation } from "react-router-dom";
import { getVendor, addCar } from '../services/glido.service';
import Multiselect from 'multiselect-react-dropdown';

const states = [
    "Jaipur", "Banglore", "Delhi", "Gurgaon", "Hydrabad",
    "Goa", "Chandigarh", "Bhopal", "Mumbai"
];

const tagsOptions = [
    {
        name: "luxury",
        id: "luxury"
    },
    {
        name: "family",
        id: "family"
    },
    {
        name: "sports",
        id: "sports"
    },
    {
        name: "eco-friendly",
        id: "eco-friendly"
    },
    {
        name: "compact",
        id: "compact"
    }
]; // Example tags

function CarForm() {
    const search = useLocation().search;
    const [vendors, setVendors] = useState([]);
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        name: '',
        brand: '',
        price: '',
        vendor: '',
        location: '',
        car_images: [],
        transmission: 'manual',
        seater: '',
        fuel: 'petrol',
        registration_number: '',
        allowed_km_per_day: '',
        price_per_km: '',
        tags: [],
        segment: 'hatchback'
    });

    useEffect(() => {
        const user = new URLSearchParams(search).get("user");
        const password = new URLSearchParams(search).get("password");

        if (user && password) {
            if (user !== process.env.REACT_APP_USER || password !== process.env.REACT_APP_PASSWORD) {
                navigate("/");
            } else {
                handleVendorFetch();
            }
        } else {
            navigate("/");
        }
    }, [search, navigate]);

    const handleVendorFetch = async () => {
        let res = await getVendor();
        setVendors(res.vendors);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleTagsChange = (selectedList) => {
        setFormData({
            ...formData,
            tags: selectedList.map(tag => tag.id)  // Update tags with selected tag ids
        });
    };

    const handleTagsRemove = (selectedList) => {
        setFormData({
            ...formData,
            tags: selectedList.map(tag => tag.id)  // Update tags when tags are removed
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();


        let data = {
            ...formData,
            name: formData.name.toLowerCase(),
            brand: formData.brand.toLowerCase(),
            price: parseFloat(formData.price),
            location: formData.location.toLowerCase(),
            car_images: [formData.car_images],
            transmission: formData.transmission.toLowerCase(),
            seater: parseFloat(formData.seater),
            fuel: formData.fuel.toLowerCase(),
            registration_number: formData.registration_number.toLowerCase(),
            allowed_km_per_day: parseFloat(formData.allowed_km_per_day),
            price_per_km: parseFloat(formData.price_per_km),
            tags: formData.tags  // Already an array of selected tags
        };



        let res = addCar(data);

        alert("Car added");
        setFormData({
            name: '',
            brand: '',
            price: '',
            vendor: '',
            location: '',
            car_images: [],
            transmission: 'manual',
            seater: '',
            fuel: 'petrol',
            registration_number: '',
            allowed_km_per_day: '',
            price_per_km: '',
            tags: [],
            segment: 'hatchback'
        });
    };



    return (
        <div className="car-form-container">
            <h2>Add a New Car</h2>
            <form onSubmit={handleSubmit} className="car-form">
                <div className="form-group">
                    <label htmlFor="name">Car Name</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="brand">Brand</label>
                    <input
                        type="text"
                        id="brand"
                        name="brand"
                        value={formData.brand}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="price">Price</label>
                    <input
                        type="number"
                        id="price"
                        name="price"
                        value={formData.price}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="price_per_km">Price per km</label>
                    <input
                        type="number"
                        id="price_per_km"
                        name="price_per_km"
                        value={formData.price_per_km}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="allowed_km_per_day">Allowed km per day</label>
                    <input
                        type="number"
                        id="allowed_km_per_day"
                        name="allowed_km_per_day"
                        value={formData.allowed_km_per_day}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="vendor">Vendor</label>
                    <select
                        id="vendor"
                        name="vendor"
                        value={formData.vendor}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Select vendor</option>
                        {vendors.map((state, index) => (
                            <option key={index} value={state._id}>{state.name}</option>
                        ))}
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="location">Location</label>
                    <select
                        id="location"
                        name="location"
                        value={formData.location}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Select State</option>
                        {states.map((state, index) => (
                            <option key={index} value={state}>{state}</option>
                        ))}
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="car_images">Car Images</label>
                    <input
                        type="text"
                        id="car_images"
                        name="car_images"
                        onChange={handleChange}
                        multiple
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="transmission">Transmission</label>
                    <select
                        id="transmission"
                        name="transmission"
                        value={formData.transmission}
                        onChange={handleChange}
                        required
                    >
                        <option value="manual">Manual</option>
                        <option value="automatic">Automatic</option>
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="segment">Segment</label>
                    <select
                        id="segmnet"
                        name="segment"
                        value={formData.segment}
                        onChange={handleChange}
                        required
                    >
                        <option value="suv">suv</option>
                        <option value="hatchback">hatchback</option>
                        <option value="muv">muv</option>
                        <option value="sedan">sedan</option>
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="seater">Seating Capacity</label>
                    <input
                        type="number"
                        id="seater"
                        name="seater"
                        value={formData.seater}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="fuel">Fuel Type</label>
                    <select
                        id="fuel"
                        name="fuel"
                        value={formData.fuel}
                        onChange={handleChange}
                    >
                        <option value="diesel">Diesel</option>
                        <option value="ev">Electric</option>
                        <option value="petrol">Petrol</option>
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="registration_number">Registration Number</label>
                    <input
                        type="text"
                        id="registration_number"
                        name="registration_number"
                        value={formData.registration_number}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="tags">Tags (select multiple)</label>

                    <Multiselect
                        id="tag-select"
                        options={tagsOptions}
                        selectedValues={tagsOptions.filter(tag => formData.tags.includes(tag.id))}
                        onSelect={handleTagsChange}
                        onRemove={handleTagsRemove}
                        displayValue="name"
                        placeholder="tags"
                        className='multi-select'
                    />
                </div>

                <button type="submit" className="submit-button">Submit</button>
            </form>
        </div>
    );
}

export default CarForm;
