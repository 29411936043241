import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../asset/style/header.scss';
import logo from '../asset/images/logo.png';

function Header() {
    const navigate = useNavigate();
    const location = useLocation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleNavigation = (path) => {
        navigate(path);
        setIsMenuOpen(false); // Close menu when navigating
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className={`main-container ${location.pathname !== "/" ? "active" : ""}`}>
            <div className="logo" onClick={() => handleNavigation('/')}>
                <img src={logo} />
            </div>

            <div className="hamburger" onClick={toggleMenu}>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
            </div>

            <div className={`nav-links ${isMenuOpen ? "open" : ""}`}>
                <div className={`simple-button ${location.pathname == "/" ? "active" : ""}`} onClick={() => handleNavigation('/')}>
                    Home
                </div>
                <div className={`simple-button ${location.pathname == "/about" ? "active" : ""}`} onClick={() => handleNavigation('/about')}>
                    About
                </div>
            </div>
        </div>
    );
}

export default Header;
