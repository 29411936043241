import React, { useState, useEffect } from 'react';
import '../asset/style/car.scss'; // Make sure to import the CSS file
import Header from './header'
import Multiselect from 'multiselect-react-dropdown';
import Modal from './modal';
import Fuel from '../asset/images/fuel.png'
import transmission from '../asset/images/transmission.png'
import seat from '../asset/images/seat.png'
import success from '../asset/images/success.png'

import funnel from '../asset/images/funnel.png'
import { getCars, getCarsByLocation, getCarsByBrands, registerBooking } from '../services/glido.service';
import { useLocation } from "react-router-dom";

function Cars() {

    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        startDate: '',
        endDate: '',
        address: ''
    });

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const limit = 20;
    const [cars, setCars] = useState([]);
    const [isModal, setIsModal] = useState(false)
    const [isFilter, setIsFilter] = useState(false)
    const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(false); // New state for dropdown
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
    const [bookingCarId, setBookingCarId] = useState(null);
    const [locationOptions, setLocationOptions] = useState([]);
    const [brandOptions, setbrandOptions] = useState([]);

    const [tags, setTags] = useState([]);
    const [location, setLocation] = useState([]);
    const [brand, setBrand] = useState([]);
    const [name, setName] = useState('');
    const [fuel, setFuel] = useState('');
    const [seater, setSeater] = useState(null);
    const [segment, setSegment] = useState(null);
    const search = useLocation().search;

    const [selectedLocation, setSelectedLocation] = useState([]);
    const [currentstep, setCurrentStep] = useState(0)
    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    useEffect(() => {

        handleGetCars();
        handleGetCarsByLocation();
        handleGetCarsByBrands();

    }, [tags, location, brand, name, fuel, seater, segment])

    useEffect(() => {
        const querylocation = new URLSearchParams(search).get("location");
        if (querylocation) {
            setTimeout(function () {
                setLocation([querylocation]);
                setSelectedLocation([{ name: capitalizeFirstLetter(querylocation), id: querylocation }]);
            }, 100)

        }
    }, []);

    const handleFuelChange = (type) => {
        setFuel(type)
    }

    const handleGetCars = async (page = 1) => {
        // Initialize the query params with page and limit
        let queryParams = `page=${page}&limit=${limit}`;

        // Add brand, location, and tags to the query params only if they are not empty
        if (brand.length > 0) {
            queryParams += `&brand=${brand.join(',')}`;
        }

        if (location.length > 0) {
            queryParams += `&location=${location.join(',')}`;
        }

        if (tags.length > 0) {
            queryParams += `&tags=${tags.join(',')}`;
        }

        if (name.length > 0) {
            queryParams += `&name=${name}`;
        }

        if (fuel.length > 0) {
            queryParams += `&fuel=${fuel}`;
        }

        if (seater) {
            queryParams += `&seater=${seater}`;
        }

        if (segment) {
            queryParams += `&segment=${segment}`;
        }

        // Make the API call
        let response = await getCars(queryParams);

        // Update state with the response data
        setCars(response.cars);
        setCurrentPage(response.page);
        setTotalPages(response.totalPages);

    };

    const handleGetCarsByLocation = async () => {
        let response = await getCarsByLocation();
        setLocationOptions(response.map((location) => {
            return { name: capitalizeFirstLetter(location), id: location }
        }))
    }

    const handleGetCarsByBrands = async () => {

        let response = await getCarsByBrands();
        setbrandOptions(response.map((brand) => {
            return { name: capitalizeFirstLetter(brand), id: brand }
        }))
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // You can handle form submission her

        let data = {
            carId: bookingCarId,
            name: formData.name,
            address: formData.address,
            contact: formData.phone,
            startDate: formData.startDate,
            endDate: formData.endDate
        }
        let response = registerBooking(data);
        setFormData({ name: '', phone: '', startDate: '', endDate: '', address: '' })
        setCurrentStep(1)        
    };


    let MultiSelectStyle = {
        inputField: {
            width: "100%",
            height: "auto",
            padding: "10px 15px",
            boxSizing: "border-box",
            textAlign: "left",
            overflow: "hidden",
            "@media (max-width: 768px)": {
                width: "100%",  // Full width on tablets and smaller devices
                height: "35px",
                padding: "8px 10px"
            },
            "@media (max-width: 480px)": {
                height: "30px",
                padding: "6px 8px"
            }
        },
        option: {
            backgroundColor: "#FFA500",
            color: "#35484f",
            padding: "8px 15px",
            cursor: "pointer",
            width: "250px",
            "&:hover": {
                backgroundColor: "#FFA500",
                color: "white"
            },
            "@media (max-width: 768px)": {
                width: "100%",  // Full width on tablets and smaller devices
            },
            "@media (max-width: 480px)": {
                width: "100%",
                padding: "6px 10px"  // Adjust padding for smaller screens
            }
        },
        optionListContainer: {
            width: "auto",
        },
        optionContainer: {
            width: "150px",
            "@media (max-width: 768px)": {
                width: "50%",  // Full width on tablets and smaller devices
            },
            "@media (max-width: 480px)": {
                width: "50%",  // Full width on mobile devices
            }
        },
        chips: {
            backgroundColor: "#35484f",
            borderRadius: "4px",
            padding: "5px",
            margin: "2px",  // Add margin to separate chips
            maxWidth: "100%",
            "@media (max-width: 768px)": {
                padding: "4px"  // Reduce padding for tablets
            },
            "@media (max-width: 480px)": {
                padding: "3px"  // Reduce padding for mobile devices
            }
        },
        searchBox: {
            width: "150px",
            borderRadius: "10px",
            backgroundColor: "white",
            minHeight: "40px",
            display: "flex",
            alignItems: "center",
            //flexWrap: "wrap",
            overflowY: "hidden",
            maxHeight: "50px",
            scrollbarWidth: "none",
            "@media (max-width: 768px)": {
                height: "35px",
            },
            "@media (max-width: 480px)": {
                height: "30px",
            }
        }
    };

    const tagsOptions = [
        { name: "luxury", id: "luxury" },
        { name: "family", id: "family" },
        { name: "sports", id: "sports" },
        { name: "eco-friendly", id: "eco-friendly" },
        { name: "compact", id: "compact" }
    ];

    const handleSelect = (selectedList, key) => {
        let updatedList = selectedList.map(tag => tag.id)


        console.log(selectedList, key)
        if (key == "tags") {
            setTags(updatedList);
        } else if (key == "location") {
            setLocation(updatedList)
        } else if (key == "brand") {
            setBrand(updatedList)
        }
    }

    const handleRemove = (selectedList, key) => {
        let updatedList = selectedList.map(tag => tag.id)

        if (key == "tags") {
            setTags(updatedList);
        } else if (key == "location") {
            setLocation(updatedList)
        } else if (key == "brand") {
            setBrand(updatedList)
        }
    }

    const handleCarSeach = (e) => {
        let name = e.target.value
        setName(name);
    }

    const handleFilterToggle = () => {
        setIsFilterDropdownOpen(!isFilterDropdownOpen); // Toggle the filter dropdown
    };

    const handleApplyFilters = () => {
        setIsFilterDropdownOpen(false); // Close the dropdown after applying filters
    };

    function capitalizeFirstLetter(string) {
        return [...string][0].toUpperCase() + [...string].slice(1).join('')
    }

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            handleGetCars(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            handleGetCars(currentPage - 1);
        }
    };

    

    const handleBookingModalClose = ()=>{
        if(currentPage ==0 ){
            setBookingCarId(null); setIsModal(false)
        }else if (currentPage ==1){
            setBookingCarId(null);
            setIsModal(false);
            setCurrentStep(0)
        }
    }
    return (
        <>

            <Header />

            <div className='filter-modal'>
                <Modal open={isFilter} >

                    <div className="modal-header">
                        <div className="close-button" onClick={() => { setIsFilter(false) }}>X</div>
                        <h2 className="modal-title">Filters</h2>
                    </div>

                    <div className='filters'>

                        <div className='filter'>
                            <div className='label'>Fuel</div>

                            <div className="segmented-control">
                                <button className={`segment ${fuel == 'petrol' ? "active" : ""}`} onClick={() => handleFuelChange("petrol")}>Petrol</button>
                                <button className={`segment ${fuel == 'ev' ? "active" : ""}`} onClick={() => handleFuelChange("ev")}>EV</button>
                                <button className={`segment ${fuel == 'diesel' ? "active" : ""}`} onClick={() => handleFuelChange("diesel")}>Diesel</button>
                            </div>
                        </div>

                        <div className='filter'>
                            <div className='label'>Brand</div>
                            <Multiselect
                                id="brand-select"
                                options={brandOptions}
                                selectedValues={[]}
                                onSelect={(e) => handleSelect(e, "brand")}
                                onRemove={(e) => handleRemove(e, "brand")}
                                displayValue="name"
                                placeholder="" // Remove placeholder text
                                className='multi-select'
                                style={MultiSelectStyle}
                            />
                        </div>

                        <div className='filter'>
                            <div className='label'>Seating Capacity</div>

                            <div className="features" >
                                <div className={`feature-card ${seater == 5 ? "active" : ""}`} onClick={() => { setSeater(5) }}>
                                    <div className="icon laurel-icon"></div>
                                    <h3>5 Seater</h3>
                                    <p>Comfortable and spacious, perfect for family trips or group travel</p>
                                </div>
                                <div className={`feature-card ${seater == 7 ? "active" : ""}`} onClick={() => { setSeater(7) }}>
                                    <div className="icon platter-icon"></div>
                                    <h3>7 seater</h3>
                                    <p>Versatile and roomy, ideal for larger families or group adventures</p>
                                </div>
                            </div>
                        </div>

                        <div className='filter'>
                            <div className='label'>Car segment</div>

                            <div className="property-types">
                                <div className={`property-type ${segment == "hatchback" ? "active" : ""}`} onClick={() => { setSegment('hatchback') }}>
                                    <div className="icon house-icon"></div>
                                    <span>HatchBack</span>
                                </div>
                                <div className={`property-type ${segment == "suv" ? "active" : ""}`} onClick={() => { setSegment('suv') }}>
                                    <div className="icon flat-icon"></div>
                                    <span>SUV</span>
                                </div>
                                <div className={`property-type ${segment == "sedan" ? "active" : ""}`} onClick={() => { setSegment('sedan') }}>
                                    <div className="icon guesthouse-icon"></div>
                                    <span>Sedan</span>
                                </div>
                                <div className={`property-type ${segment == "muv" ? "active" : ""}`} onClick={() => { setSegment('muv') }}>
                                    <div className="icon hotel-icon"></div>
                                    <span>Muv</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="footer">
                        <button className="clear-button" onClick={() => { setSegment(null); setBrand([]); setFuel(""); setSeater('') }}>Clear all</button>
                        <button className="show-button" onClick={() => { setIsFilter(false) }}>Apply</button>
                    </div>
                </Modal>

            </div>

            <div className='booking-form-container'>
                <Modal open={isModal} >

                    {
                        currentstep == 0 ?
                            <>
                                <div className="modal-header">
                                    <div className="close-button" onClick={handleBookingModalClose}>X</div>
                                    <h2 className="modal-title">Booking</h2>
                                </div>

                                <form className="booking-form">
                                    <div className="form-group">
                                        <label htmlFor="name">Name:</label>
                                        <input
                                            type="text"
                                            id="name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="phone">Phone Number:</label>
                                        <input
                                            type="tel"
                                            id="phone"
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="startDate">From Date:</label>
                                        <input
                                            type="date"
                                            id="startDate"
                                            name="startDate"
                                            value={formData.startDate}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="endDate">End Date:</label>
                                        <input
                                            type="date"
                                            id="endDate"
                                            name="endDate"
                                            value={formData.endDate}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="address">Address:</label>
                                        <textarea
                                            id="address"
                                            name="address"
                                            value={formData.address}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>

                                </form>

                                <div className="footer">
                                    <button className="clear-button" onClick={() => { setFormData({ name: '', phone: '', startDate: '', endDate: '', address: '' }) }}>Clear all</button>
                                    <button className="show-button simple-button" onClick={handleSubmit}>Submit</button>
                                </div>
                            </>

                            : currentstep == 1 ?
                                <>
                                 <div className="modal-header">
                                    <div className="close-button" onClick={handleBookingModalClose}>X</div>
                                    <h2 className="modal-title">Booking</h2>
                                </div>

                                <form className="booking-form">
                                   
                                    <img  src={success} />
                                    <h2>Your booking request is confirmed!</h2>
                                    <p>Get ready for an eco-friendly ride with Glido. We'll notify you with further details shortly. Thank you for choosing sustainability!</p>
                                </form>

                               

                                <div className="footer">
                                    <button className="clear-button" ></button>
                                </div>
                                </>
                                :
                                <></>
                    }


                </Modal>
            </div>



            <div className='main-car-container'>

                <div className='main-filter-container'>


                    <div className='filter-container'>

                        <input className='search-bar-container' placeholder='Search Car' onChange={handleCarSeach}>

                        </input>

                        <div className='filter-bar-container' onClick={(e) => { setIsFilter(true) }}>
                            <img src={funnel} /> <p>Filters</p>
                        </div>
                    </div>

                    <div className='filter'>

                        <Multiselect
                            id="tags-select"
                            options={tagsOptions}
                            selectedValues={[]}
                            onSelect={(e) => handleSelect(e, "tags")}
                            onRemove={(e) => handleRemove(e, "tags")}
                            displayValue="name"
                            placeholder="Tags"
                            className='multi-select'
                            style={MultiSelectStyle}
                        />
                        <Multiselect
                            id="location-select"
                            options={locationOptions}
                            selectedValues={selectedLocation}
                            onSelect={(e) => handleSelect(e, "location")}
                            onRemove={(e) => handleRemove(e, "location")}
                            displayValue="name"
                            placeholder="Location"
                            className='multi-select'
                            style={MultiSelectStyle}
                        />
                    </div>

                </div>




                <div className={`cars-container`} >
                    {cars.map((item) => (
                        <>
                            <div key={item.id} className={`car-item`}>
                                <div className='sub-car-item'>

                                    <img src={item.car_images[0]} alt={item.name} />
                                    <div className='inner-car-item'>

                                        <div className='car-details'>
                                            <p className='car-name'>{capitalizeFirstLetter(item.name)}</p>
                                            <p className='brand'>{capitalizeFirstLetter(item.brand)}</p>
                                        </div>

                                        <div className='car-data'>
                                            <p className='car-data-text'><img src={seat} /> {item.seater}</p>
                                            <p className='car-data-text'><img src={transmission} /> {capitalizeFirstLetter(item.transmission)}</p>
                                            <p className='car-data-text'><img src={Fuel} /> {capitalizeFirstLetter(item.fuel)}</p>
                                        </div>

                                        {
                                            !isMobileView ?

                                                <>
                                                    <div className='vendor-details'>
                                                        <p className='vendor-name'>{capitalizeFirstLetter(item.vendor)}</p>
                                                        <p className='vendor-rent'>₹{item.price}</p>

                                                    </div>

                                                    <div onClick={(e) => { setBookingCarId(item._id); setIsModal(true) }} className='simple-button'>Book</div>
                                                    <div className='per_day_km'> {item.allowed_km_per_day}km/day | ₹{item.price_per_km} per extra km</div>
                                                </>
                                                :
                                                <></>
                                        }

                                    </div>

                                </div>

                                {
                                    isMobileView ?

                                        <>
                                            <div className='vendor-details'>
                                                <p>{capitalizeFirstLetter(item.vendor)}</p>
                                                <p>₹{item.price}</p>

                                            </div>

                                            <div onClick={(e) => { setBookingCarId(item._id); setIsModal(true) }} className='simple-button mobile'>Book</div>
                                            <div className='per_day_km'> {item.allowed_km_per_day}km/day | ₹{item.price_per_km} per extra km</div>
                                        </>
                                        :
                                        <></>
                                }

                            </div>
                        </>


                    ))}
                </div>

                <div className="pagination-controls">
                    <button
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                        className="simple-button"
                    >
                        &laquo;
                    </button>

                    <span>Page {currentPage} of {totalPages}</span>

                    <button
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                        className="simple-button"
                    >
                        &raquo;
                    </button>
                </div>

            </div>





        </>

    );
}

export default Cars;
